// import React from 'react'
// import { Switch, Route } from 'react-router'

// // import About from '../about/About'
// import Terms from '../terms/Terms'
// import Privacy from '../privacy/Privacy'
// import NotFound from '../notFound/NotFound'
// import Clock from '../clock/Clock'
// // import Timer from '../timer/Timer'
// // import Stopwatch from '../stopwatch/Stopwatch'

// import { LANGUAGES } from '../_i18n/languages'
// import Sitemap from '../sitemap/Sitemap'

// const MultiLanguageRoute = (props) => {
//     //const defaultLanguage = LANGUAGES.en.urlLang
//     let hasLang = props.computedMatch.params.lang
//     hasLang = hasLang ? hasLang : 'en'
//     const is404Page = props.location.pathname.split('/')[3]
//     //const isBasePathWithoutLang = props.location.pathname === "/"
//     const currentLanguage = LANGUAGES[hasLang]

//     //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
//     //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
//     if (!currentLanguage || is404Page) return <Route component={NotFound} />

//     return <Route {...props} />
// }

// export default props => (
//     <React.Fragment>
//         <Switch>
//             <Route exact path='/' component={Clock} />
//             <Route path='/terms' component={Terms} />
//             <Route path='/privacy' component={Privacy} />
//             <Route path='/genSitemap' component={Sitemap} />
//             {/* <MultiLanguageRoute path='/:lang/countdown-timer' component={Timer} />
//             <MultiLanguageRoute path='/:lang/stopwatch' component={Stopwatch} /> */}
//             <MultiLanguageRoute path='/:lang' component={Clock} />
//             <Route component={NotFound} />
//         </Switch>
//     </React.Fragment>
// )


/////////////////////////


import React from 'react'
import { Switch, Route } from 'react-router'
import { LANGUAGES } from '../_i18n/languages';
import loadable from '@loadable/component'

// import About from '../about/About'
const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
// const Stopwatch = loadable(() => import('../stopwatch/Stopwatch'))
// const Timer = loadable(() => import('../timer/Timer'))
const Clock = loadable(() => import('../clock/Clock'))


const MultiLanguageRoute = (props) => {
    //const defaultLanguage = LANGUAGES.en.urlLang
    let hasLang = props.computedMatch.params.lang
    hasLang = hasLang ? hasLang : 'en'
    // let pathParam1 = props.location.pathname.split('/')[1]
    // let pathParam2 = props.location.pathname.split('/')[2]
    // let pathParam3 = props.location.pathname.split('/')[3]
    // const is404Page = props.location.pathname.split('/')[3]
    //const is404Page = (pathParam3 && pathParam1 !== 'countdown-timer') ? true : pathParam3 && pathParam3.indexOf('timer') === -1
    const is404Page = props.location.pathname.split('/')[3]
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <Route component={NotFound} />

    return <Route {...props} />
}

const router = (props) => (
    <React.Fragment>
        <Switch>
            <Route exact path='/' component={Clock} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            {/* <MultiLanguageRoute path='/:lang/countdown-timer' component={Timer} />
            <MultiLanguageRoute path='/:lang/stopwatch' component={Stopwatch} /> */}
            <MultiLanguageRoute path='/:lang' component={Clock} />
            {/* <MultiLanguageRoute path='/what-time-is-it-in/:lang' component={Clock} /> */}
            <Route component={NotFound} />
        </Switch>
    </React.Fragment>
)


export default router