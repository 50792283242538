import React from 'react'
import { FormattedMessage } from 'react-intl';

const Footer = (props) => {
    return (
        <footer className="footer mt-auto py-3">
            <div className="container">
                <span className="left">
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="/" rel="nofollow" ><FormattedMessage id="footer.home" /></a></li>
                        <li className="list-inline-item"><a href="/terms" rel="nofollow"><FormattedMessage id="footer.terms" /></a></li>
                        <li className="list-inline-item"><a href="/privacy" rel="nofollow"><FormattedMessage id="footer.privacy" /></a></li>
                    </ul>
                </span>
                <div className="d-flex justify-content-center">
                    <span className="text-muted">timenowin.net © {new Date().getFullYear()}</span>
                </div>

            </div>
        </footer>
    )
}

export default Footer