import React from 'react';
import { IntlProvider } from 'react-intl'
import translations from './translations.json'
import { LANGUAGES } from './languages.js';

export default class IntlProviderConfigured extends React.Component {
    state = {
        loading: true,
        locale: ''
    }
    localeSelected = undefined
    urlLang = ''

    componentDidMount() {
        let currentUrlLang = window.location.pathname.split('/')[1]
        currentUrlLang = currentUrlLang.length>2 && window.location.pathname.split('/').length > 2 ? window.location.pathname.split('/')[2] : currentUrlLang
        currentUrlLang = currentUrlLang.split('?')[0]
        let currentLanguage = LANGUAGES[currentUrlLang]
        if(!currentLanguage) currentLanguage = LANGUAGES['en']
        this.setState({ locale: currentLanguage.code, loading: false })
    }

    static preInitStore(url) {
        return new Promise((resolve, reject) => {
            let currentUrlLang = url.split('/')[1]
            currentUrlLang = currentUrlLang.length > 2 && url.split('/').length > 2 ? url.split('/')[2] : currentUrlLang
            currentUrlLang = currentUrlLang.split('?')[0]
            const currentLanguage = LANGUAGES[currentUrlLang]
            IntlProviderConfigured.localeSelected = (currentLanguage) ? currentLanguage.code : 'en-US'
            this.urlLang = (currentLanguage && !url.split('/')[3]) ? currentLanguage.urlLang : 'en'
            resolve(true)
        });
    }

    render() {
        const locale = this.state.locale !== '' ? this.state.locale : IntlProviderConfigured.localeSelected
        const { children } = this.props

        if(this.state.loading && IntlProviderConfigured.localeSelected===undefined) return <div>...</div>

        return (
            <IntlProvider locale={locale} messages={translations[locale]}>
                {children}
            </IntlProvider>
        )
    }
}