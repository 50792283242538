import React from 'react'

// import { FormattedMessage } from 'react-intl';
// import { LANGUAGES } from '../../_i18n/languages'

const Header = (props) => {

  // let localePath = props.location.pathname.split('/')[1]
  // let hasLang = localePath ? localePath : 'en'
  // const currentLanguage = LANGUAGES[hasLang]
  // const lang = !currentLanguage ? 'en' : hasLang

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-dark ">
        <a className="navbar-brand" href="/">
          <img src="/logo32.png" alt="timenowin.net" className="logo" />Time Now In</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href={lang==='en' ? '/':`/${lang}`} ><FormattedMessage id="header.navLink.currentTime" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`/${lang}/stopwatch`} ><FormattedMessage id="header.navLink.stopwatch" /></a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={`/${lang}/countdown-timer`} ><FormattedMessage id="header.navLink.countdownTimer" /></a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/blog" >Blog</a>
            </li>

          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header